import request from '@/utils/request';

// 列表
export function List(params) {
    return request({
        url: '/admin/subjectApply/page',
        method: 'GET',
        params
    });
}

// 详情
export function Detail(params) {
    return request({
        url: '/admin/subjectLog/detail',
        method: 'GET',
        params
    });
}

// 获取工种下拉数据
export function getWorkJson(params) {
    return request({
        url: '/admin/project/getWorkJson',
        method: 'GET',
        params
    });
}

// 获取项目下拉数据
export function getProJson(params) {
    return request({
        url: '/admin/project/page',
        method: 'GET',
        params
    });
}



